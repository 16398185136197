import React from "react";
import dynamic from 'next/dynamic';
import Link from "next/link";
import Container from "react-bootstrap/Container";
// scss
import styles from "../../Article/article.module.scss";

const ArticleData = dynamic(() => import('../../Article'));

function RecentArticles(props) {
    const recentArticlesData = props.recentArticles;
    const recentArticles = recentArticlesData.data.articles.edges;
    const recentArticlesWriters = props.articleWriterNames && JSON.parse(props.articleWriterNames);

    return <>
        <section className="pt-0">
            <Container>
                <div className={'section-title text-center'}>Recent Articles</div>
                <div className={styles.articlesCard}>
                    <ArticleData articles={recentArticles} articleWriterNames={recentArticlesWriters} readingTime={props.readingTime && JSON.parse(props.readingTime)} />
                </div>
                <div className="text-center">
                    <Link
                        href={`/articles`}
                        locale={false}
                        prefetch={false}
                        passHref
                        className={`btn outline-btn ${styles.btn}`}>
                        Browse All
                    </Link>
                </div>
            </Container>
        </section>
    </>;
}

export default RecentArticles;